// import logo from './logo.svg';
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Home from "./component/Home";
import Login from "./component/Login";
import Sidebar from "./component/Sidebar";
import React, { useEffect, useState } from "react";
import AddUserPage from "./page/AddUserPage";
import ViewUserPage from "./page/ViewUserPage";
import ViewEmployeePage from "./page/ViewEmployeePage";
import UpdatePaySlipPage from "./page/UpdatePaySlipPage";
import PaySlip from "./component/PaySlip";
import EditProfile from "./page/EditProfilPage";
import { QueryClient, QueryClientProvider } from "react-query";
import Navbar from "./component/MobileNav";

function App() {
  const [login, setLogin] = useState(null);
  const [data, setData] = useState();
  // console.log("viewUserEditApp", viewUserEdit);
  useEffect(() => {
    setData(localStorage.getItem("login"));
  }, [localStorage.getItem("login")]);
  const value = sessionStorage.getItem("login");
  // const value = true
  const user = sessionStorage.getItem("user");
  const queryClient = new QueryClient()

  return (
    <div style={{ height: "100%" }}>
      {/* <Sidebar/> */}

      <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          {value ? (
            <>
            {/* {user=='Admin'||user==='limitedAdmin'||user==='superAdmin'? */}
            {user !=='employee'?
            (
              <>
        <Navbar/>
{
  user =='superAdmin'?
              <Routes>
                {/* <Route path="/" element={<Home setLogin={setLogin} />} />
                <Route path="/login" element={<Login login={login} />} /> */}
                <Route path="/paySlip" element={<PaySlip />} />
                <Route path="/AddUser" element={<AddUserPage />} />
                <Route path="/" element={<ViewUserPage />} />
                <Route path="/ViewEmployee" element={<ViewEmployeePage />} />
                <Route path="/updatePaySlip" element={<UpdatePaySlipPage />} />
                <Route path="/editProfile" element={<EditProfile />} />
              </Routes>
              :
              <Routes>
                {/* <Route path="/" element={<Home setLogin={setLogin} />} />
                <Route path="/login" element={<Login login={login} />} /> */}
                <Route path="/paySlip" element={<PaySlip />} />

                {/* <Route path="/AddUser" element={<AddUserPage />} /> */}
                {/* <Route path="/" element={<ViewUserPage />} /> */}
                <Route path="/" element={<ViewEmployeePage />} />
                <Route path="/updatePaySlip" element={<UpdatePaySlipPage />} />
                <Route path="/editProfile" element={<EditProfile />} />
              </Routes>
}
              </>
            )
            :(
              <>
        <Navbar/>

              <Routes>
              <Route path="/paySlip" element={<PaySlip />} />
                <Route path="/" element={<ViewEmployeePage />} />
                {/* <Route path="/updatePaySlip" element={<UpdatePaySlipPage />} /> */}
                {/* <Route path="/editProfile" element={<EditProfile />} /> */}
              </Routes>
              </>
            )
            }
              {/* <Routes>
                <Route path="/" element={<Home setLogin={setLogin} />} />
                <Route path="/login" element={<Login login={login} />} />
                <Route path="/paySlip" element={<PaySlip />} />
                <Route path="/viewUserComponent" element={<ViewUser />} />
                <Route path="/addUserComponent" element={<AddUser />} />

                <Route path="/AddUser" element={<AddUserPage />} />
                <Route path="/" element={<ViewUserPage />} />
                <Route path="/ViewEmployee" element={<ViewEmployeePage />} />
                <Route path="/updatePaySlip" element={<UpdatePaySlipPage />} />
                <Route path="/editProfile" element={<EditProfile />} />
              </Routes> */}
            </>
           ) : (
            <>
              <React.StrictMode>
                <Routes>
                <Route path="/" element={<Home setLogin={setLogin} />} />
                <Route path="/login" element={<Login login={login} />} />
                </Routes>
              </React.StrictMode>
            </>
          )} 
        </BrowserRouter>
        </QueryClientProvider>
      </React.StrictMode>
    </div>
   
   
  );
}

export default App;
