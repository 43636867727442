import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import ipfLogo from "../asset/ipfLogo.webp";
import ipfplLogo from "../asset/ipfplLogo.webp";
import sifmLogo from "../asset/sifmLogo.webp";
import { type } from "@testing-library/user-event/dist/type";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useQuery } from "react-query";
import { getPaySlipDetail } from "../globalAPI/globalAPI";
import { useLocation, useNavigate } from "react-router-dom";
import backIcon from "../asset/GoBack.svg";
import numWords from "num-words";
export default function PaySlip() {
  const componentRef = useRef([]);
  const [isLoading, setIsLoading] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Pay Slip",
    // onBeforePrint:()=>alert('printing is going on'),
    // onAfterPrint:()=>alert('printing success')
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  // useEffect(()=>{
  //   {
  //     state&&state.map.map((val)=>{

  //
  //     })
  //   }
  // },[state])

  console.log("STATE", state);
  const handleShare = () => {
    const shareInvoice = componentRef.current;

    console.log(">>>>><<<<", componentRef.current, componentRef.current.length);
    for (let i = 0; i < componentRef.current.length; i++) {
      html2canvas(componentRef.current[i]).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4", true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 30;
        pdf.addImage(
          imgData,
          "PNG",
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );
        pdf.save(`${state && state[0] && state[0]?.month} Pay Slip.pdf`);
      });
    }
  };
  const companyLogo = sessionStorage.getItem("logo");
  const companyName = sessionStorage.getItem("companyname");

  //  const { state, isLoading, isError, isRefetching } = useQuery(
  //   "customerResisterTable",
  //   getPaySlipDetail
  // );

  console.log("paySlipstate_state", isLoading);

  const generatePdf = async () => {
    setIsLoading(true);
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;
    const pageWidth = pdf.internal.pageSize.width;

    for (let i = 0; i < componentRef.current.length; i++) {
      const canvas = await html2canvas(componentRef.current[i], { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      if (i > 0) {
        pdf.addPage();
      }
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    }

    setIsLoading(false);
    pdf.save(`${state && state[0] && state[0]?.month} Pay Slip.pdf`);
  };
  // useEffect(()=>{
  //   if(isLoading==true){

  //   }
  // },[isLoading])
  const [text, setText] = useState(
    numWords(Number(state[0]?.netpay.replace(/,/g, "")))
  );
  console.log("VLAUEEE", text, state[0]?.netpay.replace(/,/g, ""));
  function capitalizeFirstLetter(string) {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  return (
    <>
      <div
        className="container --changedNav_table"
        style={{
          height: "100%",
          width: "100%",
          // backgroundColor: "yellowgreen",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          // flexWrap: "wrap",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <img
              onClick={() => {
                isLoading ? <></> : navigate(-1);
              }}
              style={{ width: "60px", height: "60px" }}
              src={backIcon}
              alt="Back Page"
            />
          </div>
          <button
            style={{
              width: "100px",
              height: "30px",
              borderRadius: "6px",
              margin: "10px",
              backgroundColor: "yellowgreen",
              color: "#000",
            }}
            disabled={isLoading}
            type="submit"
            onClick={() => {
              generatePdf();
            }}
          >
            {isLoading ? "Loading..." : "Download"}
          </button>
        </div>
        {state.map((value, i) => {
          return (
            <>
              <div
                key={i}
                ref={(el) => (componentRef.current[i] = el)}
                className="paySlipInnerContainer"
                style={{
                  height: "750px",
                  width: "1100px",
                  backgroundColor: "white",
                  padding: "10px",
                }}
              >
                <div
                  className="payslipHeader"
                  style={{
                    height: "130px",
                    width: "100%",
                    display: "flex",
                    marginBottom: "4px",
                    border: "2px solid black",
                  }}
                >
                  <div
                    className="paySlipCompanyName"
                    style={{
                      textAlign: "center",
                      width: "85%",
                      margin: "auto auto",
                    }}
                  >
                    <h2 style={{ fontSize: "24px" }}>
                      {companyName === "SIFM"
                        ? "Sampoornam Integrated Facility Management Private Limited"
                        : companyName === "IPF"
                        ? "INTELLIGENT PROTECTION FORCE PRIVATE LIMITED"
                        : companyName === "IPF FMS"
                        ? "IPF FACILITY MANAGEMENT (INDIA) PVT LTD"
                        : ""}
                    </h2>
                    <h5>{value && value?.companyaddress} </h5>
                    <h5>{value && value?.month} </h5>
                  </div>
                  <div
                    className="paySlipCompanyLogo"
                    style={{ margin: "auto auto" }}
                  >
                    <img
                      style={{ width: "100%", height: "120px" }}
                      src={companyLogo}
                      alt="ipfplLogo"
                    />
                  </div>
                  <div></div>
                </div>
                <div className="PaySlipEmployeeDetailsContainer">
                  <div
                    className="paySlipEmployeeDetails"
                    style={{
                      height: "180px",
                      width: "100%",
                      border: "2px solid black",
                      padding: "10px",
                      display: "flex",
                      overflow: "hidden",
                      justifyContent: "space-between",
                      padding: "10px 30px",
                    }}
                  >
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "115px" }}>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            Ecode
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            : {value && value?.eCode}
                          </p>
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "115px" }}>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            Name{" "}
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            : {value && value?.name}
                          </p>
                        </div>
                      </div>

                      {/*  */}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "115px" }}>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            Days Present
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            : {value && value?.daysPresent}
                          </p>
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "115px" }}>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            Designation{" "}
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            : {value && value?.designation}
                          </p>
                        </div>
                      </div>
                      {/*  */}
                      {/*  */}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "115px" }}>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            Bank
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            : {value && value?.bank}
                          </p>
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "115px" }}>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            {" "}
                            A/C No{" "}
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            : {value && value?.acNo}
                          </p>
                        </div>
                      </div>
                      {/*  */}
                    </div>
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "190px" }}>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            UAN No
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            : {value && value?.uanNo}
                          </p>
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "190px" }}>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            ESI No{" "}
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            : {value && value?.esiNo}
                          </p>
                        </div>
                      </div>
                      {/*  */}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "190px" }}>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            Site Name
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            : {value && value?.worklocation}
                          </p>
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "190px" }}>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            Date of Joining
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            : {value && value?.dateofjoining}
                          </p>
                        </div>
                      </div>
                      {/*  */}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "190px" }}>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            IFSC code
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              margin: "0px",
                              padding: "0px",
                              fontSize: "18px",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            : {value && value?.ifsccode}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="paySlipSalaryDetailContainer"
                    style={{
                      height: "420px",
                      width: "100%",
                      border: "2px solid black",
                      padding: "0px",
                      marginTop: "6px",
                    }}
                  >
                    <div
                      className="paySlipSalaryDetailHeader"
                      style={{
                        height: "40px",
                        width: "100%",
                        borderBottom: "2px solid black",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "359px",
                          borderRight: "2px solid black",
                          fontSize: "16px",
                          fontWeight: "500",
                          padding: "8px",
                        }}
                      >
                        EARNINGS
                      </div>
                      <div
                        style={{
                          width: "700px",
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "center",
                          padding: "8px 0px",
                        }}
                      >
                        {" "}
                        DEDUCTIONS
                      </div>
                    </div>
                    <div
                      className="paySlipSalaryDetailBody"
                      style={{
                        height: "260px",
                        width: "100%",
                        padding: "0px",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "392px",
                          height: "266px",
                          borderRight: "2px solid black",
                          fontSize: "16px",
                          fontWeight: "500",
                          padding: "8px",
                        }}
                      >
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">BASIC</div>{" "}
                          <span>{value && value?.basic}</span>{" "}
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">D.A.</div>{" "}
                          <span>{value && value?.da}</span>{" "}
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">H.R.A.</div>{" "}
                          <div>{value && value?.hra}</div>{" "}
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">W.A</div>{" "}
                          <div>{value && value?.wa}</div>{" "}
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">O.T</div>{" "}
                          <div>
                            {value && value?.ot ? value && value?.ot : "0"}
                          </div>{" "}
                        </div>

                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">BONUS</div>{" "}
                          <div>{value && value?.bonus}</div>{" "}
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">OA</div>{" "}
                          <div>{value && value?.oa}</div>{" "}
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">L.W</div>{" "}
                          <div>
                            {value && value?.lw ? value && value?.lw : "0"}
                          </div>{" "}
                        </div>
                        {/* <div className="d-flex justify-content-between" style={{paddingRight:'9px'}}>
                          {" "}
                          <div className="employeeDetailsText">
                            P.I 
                          </div>{" "}
                          <div>{value && value?.pi}</div>{" "}
                        </div> */}
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">DAdd</div>{" "}
                          <div>{value && value?.dAdd}</div>{" "}
                        </div>
                        {/* <div className="d-flex justify-content-between" style={{paddingRight:'9px'}}>
                          {" "}
                          <div className="employeeDetailsText">
                            OTHERS 
                          </div>{" "}
                          <div>{value && value?.othersEarnings===null?0:value&&value?.othersEarnings}</div>{" "}
                        </div> */}
                      </div>
                      <div
                        style={{
                          width: "392px",
                          height: "266px",
                          borderRight: "2px solid black",
                          fontSize: "16px",
                          fontWeight: "500",
                          padding: "8px",
                        }}
                      >
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">PF </div>{" "}
                          <div>{value && value?.pf}</div>{" "}
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">ESI</div>{" "}
                          <div>{value && value?.esi}</div>{" "}
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">PR-TAX</div>{" "}
                          <div>{value && value?.prTAX}</div>{" "}
                        </div>
                        {/* <div className="d-flex justify-content-between" style={{paddingRight:'9px'}}>
                          {" "}
                          <div className="employeeDetailsText">SW </div>{" "}
                          <div>{value && value?.sw}</div>{" "}
                        </div> */}
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">DDED</div>{" "}
                          <div>{value && value?.dDed}</div>{" "}
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          {/* new Field */}
                          <div className="employeeDetailsText">LWF</div>{" "}
                          <div>{(value && value?.labourWelfareFund) || 0}</div>{" "}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "392px",
                          height: "266px",
                          borderRight: "2px solid black",
                          fontSize: "16px",
                          fontWeight: "500",
                          padding: "8px",
                        }}
                      >
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">
                            ADVANCE
                          </div>{" "}
                          <div>{value && value?.advance}</div>{" "}
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">LOAN</div>{" "}
                          <div>
                            {value && value?.loan ? value && value?.loan : "0"}
                          </div>{" "}
                        </div>

                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">
                            UNIFORM
                          </div>{" "}
                          <div>{value && value?.uniform}</div>{" "}
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">ROOM</div>{" "}
                          <div>{value && value?.room}</div>{" "}
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">
                            INSURANCE
                          </div>{" "}
                          <div>{value && value?.insurance}</div>{" "}
                        </div>

                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">MESS</div>{" "}
                          <div>
                            {value && value?.mess === null
                              ? 0
                              : value && value?.mess}
                          </div>{" "}
                        </div>
                        {/* <div className="d-flex justify-content-between" style={{paddingRight:'9px'}}>
                          {" "}
                          <div className="employeeDetailsText">
                            WATER                         </div>{" "}
                          <div>{value && value?.water}</div>{" "}
                        </div>
                        <div className="d-flex justify-content-between" style={{paddingRight:'9px'}}>
                          {" "}
                          <div className="employeeDetailsText">
                            SECURITY GUARD
                          </div>{" "}
                          <div>{value && value?.securityGuard}</div>{" "}
                        </div> */}
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">OTHERS</div>{" "}
                          <div>
                            {value && value?.othersDeduction === null
                              ? 0
                              : value && value?.othersDeduction}
                          </div>{" "}
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ paddingRight: "9px" }}
                        >
                          {" "}
                          <div className="employeeDetailsText">
                            INCREMENTED{" "}
                          </div>{" "}
                          <div>
                            {value && value?.nextMonthSalary === null
                              ? 0
                              : value && value?.nextMonthSalary}
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                    <div
                      className="paySlipSalaryDetailHeader"
                      style={{
                        height: "37px",
                        width: "100%",
                        borderTop: "2px solid black",
                        borderBottom: "2px solid black",
                        display: "flex",
                        marginTop: "6px",
                      }}
                    >
                      <div
                        style={{
                          width: "359px",
                          fontSize: "16px",
                          fontWeight: "500",
                          borderRight: "2px solid black",
                          padding: "8px ",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        {" "}
                        <i style={{ marginRight: "20px" }}>GROSS </i> -
                        <p style={{ marginLeft: "30px" }}>
                          {" "}
                          {value && value?.gross}
                        </p>
                      </div>
                      <div style={{ width: "359px" }}></div>
                      <div
                        style={{
                          width: "359px",
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "",
                          padding: "8px ",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        {" "}
                        <i style={{ marginRight: "20px" }}>
                          Total Deduction{" "}
                        </i>{" "}
                        -
                        <p style={{ marginLeft: "30px" }}>
                          {" "}
                          {value && value?.totalded}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        height: "40px",
                        width: "100%",
                        display: "flex",
                        borderBottom: "2px solid black",
                      }}
                    >
                      <div
                        style={{
                          width: "718px",
                          borderRight: "2px solid black",
                          padding: "8px ",
                        }}
                      >
                        <i style={{ fontWeight: 700 }}>
                          {capitalizeFirstLetter(text && text)} Rupess Only
                        </i>
                      </div>
                      <div
                        style={{
                          width: "359px",
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "",
                          padding: "4px ",
                          paddingRight: "10px",
                          display: "flex",
                          justifyContent: "end",
                          backgroundColor: "lightgray",
                        }}
                      >
                        {" "}
                        <i style={{ marginRight: "20px", fontSize: "22px" }}>
                          Net-Pay{" "}
                        </i>{" "}
                        -
                        <p style={{ marginLeft: "30px", fontSize: "22px" }}>
                          {value && value?.netpay}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "600",
                        padding: "6px",
                      }}
                    >
                      <i>
                        THIS IS COMPUTER GENERATED PAYSLIP, HENCE NO SIGNATURE
                        IS REQUIRED
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
